import { SET_AUTH_INIT, SET_AUTH_LOADER } from "../types";

export const setAuthLoader = (loader) => ({
  type: SET_AUTH_LOADER,
  payload: loader,
});

export const setAuthInit = (authInit) => ({
  type: SET_AUTH_INIT,
  payload: authInit,
});
