import { normalizeUser } from "../../helpers/normalizeUrser";
import getJwt from "../../helpers/getJwt";
import { signOut as authSignout } from "aws-amplify/auth";
import { get } from "aws-amplify/api";
import { SET_SSO_ERROR, SET_USER } from "../types";
import { concat } from "lodash";
import { initialState } from "../reducers/user";

export const getUserData = (user) => async (dispatch) => {
  try {
    const normalizedUser = normalizeUser(user);
    const jwt = await getJwt();
    const headers = { Authorization: `Bearer ${jwt}` };

    const userDoc = await (
      await get({
        apiName: "users",
        path: `/users/${normalizedUser.userId}`,
        options: {
          headers,
        },
      }).response
    ).body.json();

    const points = await (
      await get({
        apiName: "community",
        path: `/users/${normalizedUser.userId}/points`,
        options: {
          headers,
        },
      }).response
    ).body.json();

    const surveyprofile = await (
      await get({
        apiName: "community",
        path: `/users/${normalizedUser.userId}/surveyprofile`,
        options: {
          headers,
        },
      }).response
    ).body.json();

    const { affiliateId, category, organisation, profile } = userDoc;

    dispatch(
      setUser({
        ...normalizedUser,
        roles: concat(normalizedUser.roles, [category]),
        affiliateId,
        organisation,
        profile,
        points,
        surveyprofile,
      })
    );
  } catch (e) {
    throw e;
  }
};

export const signOut = () => async (dispatch) => {
  await authSignout();
  dispatch(setUser(initialState));
};

export const setUser = (user) => ({
  type: SET_USER,
  payload: user,
});

export const setSsoError = (error) => ({
  type: SET_SSO_ERROR,
  error,
});
